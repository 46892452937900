import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Icon,
  Tooltip,
  Badge,
  Drawer,
  Typography,
  Menu, 
  MenuItem,
  ListItem,
  ListItemText,
  ListSubheader
} from '@material-ui/core';
import {
  NotePlus,
  Earth,
  FormatListBulleted,
  HelpCircleOutline,
  DatabaseSearch,
  Email,
  ChartMultiline,
  Cog,
  TransitConnectionVariant
} from 'mdi-material-ui';
import RequestInbox from "./Requests/RequestInbox";
import { RequestColor } from '../constants/RequestConstants';
import { NavigationContext } from '../contexts/navigation';
import { UIContext } from '../contexts/ui';
import { dashboardConfig } from '../environment.js';
import { PortCall, RequestState, PortCallStatus } from '../models';
import { DataStoreContext } from '../contexts/dataStoreContext';
import { useTranslation } from 'react-i18next';
import RouteConstants from '../constants/RouteConstants';
import useQuery from '../hooks/useQuery';

const navBarWidth = "4rem";
const drawerWidth = "70vw";
const appHeaderHeight = "4.5rem";
const zIndex = 14000;
const paddingTop = "3.5rem";
const transitionTime = 300;

/**
 * 
 *  NavBarVertical
 *  Contains links to main sections of application
 *  and menu for user account.
 * 
 */

const useStyles = makeStyles(theme => {
  return {
    navBarContainer: {
      width: navBarWidth,
      // Prevents berth display from squashing nav bar width
      minWidth: navBarWidth,
      paddingTop: paddingTop,
      borderRight: '0.0625rem solid #e0e0e0',
      backgroundColor: theme.palette.grey[100],
    },
    aboveAll : {
      zIndex: zIndex,
    },
    drawer: {
      width: drawerWidth,
    },
    paper: {
      top: appHeaderHeight,
      width: drawerWidth,
      backgroundColor: theme.palette.grey[100],
      paddingBottom: "1rem",
      height: "auto",
      maxHeight: `calc(95vh - ${appHeaderHeight})`,
    },
    title: {
      height: paddingTop,
      paddingTop: "1rem"
    },
    drawerContainer: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      marginLeft: navBarWidth,
      marginBottom: '1rem',
      height: 'auto',
    },
    navLink: {
      display: 'flex',
      height: '4rem',
      marginBottom: '1rem',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgba(0, 0, 0, 0.25)',
      cursor: 'pointer'
    },
    listSubHeader: {
      height: "2rem",
      display: "flex",
      alignItems: "center",
      userSelect: "none"
    },
    navLinkMenuItem: {
      textDecoration: 'none',
      color: 'rgba(0, 0, 0)',
      paddingLeft: '0.5rem' 
    },
    activeLinkMenuItem: {
      display: 'flex',
      height: '4rem',
      marginBottom: '1rem',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgba(0, 0, 0, 0.55)',
      backgroundColor: theme.palette.common.white,
    },
    activeLink: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.55)',
    },
    navLinkDisabled: {
      color: theme.palette.grey[300],
      cursor: 'text',
    },
    badge: {
      backgroundColor: RequestColor.color,
      color: "white",
    },
    requests: {
      height: 'auto',
      top: paddingTop,
    },
    noScroll: {
      height: paddingTop,
      overflow: "hidden",
    }
  }
});

const NavBarVerticalInternal = ({ requests }) => {
  const { t } = useTranslation()
  const location = useLocation();
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [above, setAbove] = useState(false);
  const [navigationContext,] = useContext(NavigationContext);
  const menuEl = useRef(null);
  const [uiContext,] = useContext(UIContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const { isVoyageAdmin } = uiContext;

  function handleDataMenuItemClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleDataMenuItemClose() {
    setAnchorEl(null);
  }

  const requestsDisabled = !(requests && requests.length > 0);

  const readOnly = uiContext.readOnly;
  const agentPortal = uiContext.agentPortal;
  const isAdmin = uiContext.isAdmin;

  // useEffect to control drawer opening
  useEffect(() => {
    if(!requestsDisabled) {
      if(above === openDrawer) return;
      if(openDrawer === true) {
        setAbove(true);
        return;
      }
    } else {
      setOpenDrawer(false);
    }
    const aboveClassTimeout = setTimeout(() => setAbove(false), transitionTime);

    return () => {
      clearTimeout(aboveClassTimeout);
    }

  }, [openDrawer, above, setAbove, requestsDisabled])

  const requestsMenuItem = () => !agentPortal && 
    <Tooltip title={`${requestsDisabled ? t('NavBarVertical.Labels.NoRequests') : t('NavBarVertical.Labels.Requests')}`}>
      <Box className={classes.navLink}>
        <Badge classes={{badge: classes.badge}}  badgeContent={ requests ? requests.length : 0} overlap='rectangular'>
          <Icon
            component="div"
            fontSize="large"
            ref={menuEl}
            onClick={requestsDisabled ? null : () => setOpenDrawer(!openDrawer)}
            id="NavRequests"
          >
            <Email fontSize="large" />
          </Icon>
        </Badge>
      </Box>
    </Tooltip>
  
  const addPortCallMenuItem = () => !agentPortal &&
    <Tooltip title={readOnly ? t('NavBarVertical.Labels.CreatePortCallDisabled') : t('NavBarVertical.Labels.CreatePortCall')}>
      <NavLink
        activeClassName={classes.activeLink}
        className={classes.navLink}
        exact
        to={"/quick"}
        disabled={readOnly}
        onClick={readOnly ? (e => e.preventDefault()) : undefined}
        id="NavCreatePortCall"
      >
        <Icon
          fontSize="large"
          color="inherit"
        >
          <NotePlus fontSize="large" />
        </Icon>
      </NavLink>
    </Tooltip>
  
  const requestPortCallMenuItem = () => agentPortal && 
    <Tooltip title={t('NavBarVertical.Labels.RequestPortCall')}>
      <NavLink
        activeClassName={classes.activeLink}
        className={classes.navLink}
        exact
        to={"/request"}
        id="NavRequestPortCall"
      >
        <Icon
          fontSize="large"
          color="inherit"
        >
          <NotePlus fontSize="large" />
        </Icon>
      </NavLink>
    </Tooltip>

    const boardMenuItem = () => 
      <Tooltip title={t('NavBarVertical.Labels.BoardView')}>
        <NavLink
          activeClassName={classes.activeLink}
          className={classes.navLink}
          exact
          to={navigationContext.defaultView}
          id="NavBoard"
        >
          <Icon
            component="div"
            fontSize="large"
            color="inherit"
          >
            <FormatListBulleted fontSize="large" />
          </Icon>
        </NavLink>
      </Tooltip>

  const voyageBoardMenuItem = () => 
    <Tooltip title={t('NavBarVertical.Labels.VoyageBoardView')}>
      <NavLink
        activeClassName={classes.activeLink}
        className={classes.navLink}
        exact
        to="/voyage-schedule"
        id="NavVoyageBoard"
      >
        <Icon fontSize="large">
          <TransitConnectionVariant fontSize="large" />
        </Icon>
      </NavLink>
    </Tooltip>

  const mapMenuItem = () => 
    <Tooltip title={t('NavBarVertical.Labels.MapView')}>
      <NavLink
        activeClassName={classes.activeLink}
        className={classes.navLink}
        to="/map"
        id="NavMap"
        // onClick={e => e.preventDefault()}
      >
        <Icon fontSize="large">
          <Earth fontSize="large" />
        </Icon>
      </NavLink>
    </Tooltip>

  const DataMenuItem = () =>
    <>
      <ListItem
        id="NavDataBrowser"
        className={(location.pathname.startsWith(RouteConstants.PORTCALL_LIST) || location.pathname.startsWith(RouteConstants.VESSEL_LIST)) ? classes.activeLinkMenuItem : classes.navLink}
        size="large"
        onClick={handleDataMenuItemClick}
        onMouseEnter={handleDataMenuItemClick}
      >
        <Icon fontSize="large">
          <DatabaseSearch fontSize="large" />
        </Icon>
      </ListItem>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "center", horizontal: "right" }}
        onClose={handleDataMenuItemClose}
        MenuListProps={{ onMouseLeave: handleDataMenuItemClose }}
      >
        <ListSubheader className={classes.listSubHeader}>
          <Typography variant="body2" noWrap style={{fontWeight: 500, lineHeight: '48px'}}>{t('NavBarVertical.Labels.DataBrowser')}</Typography>
        </ListSubheader>
          
        <MenuItem key={'NavPortCallList-key'}>
          <NavLink
            className={classes.navLinkMenuItem}
            to={RouteConstants.PORTCALL_LIST}
            onClick={handleDataMenuItemClose}
            id="NavPortCallList"
          >
            <ListItemText primary={t('NavBarVertical.Labels.PortCalls')} />
          </NavLink>
        </MenuItem>
        <MenuItem key={'NavVesselList-key'}>
          <NavLink
              className={classes.navLinkMenuItem}
              to={RouteConstants.VESSEL_LIST}
              onClick={handleDataMenuItemClose}
              id="NavVesselList"
            >
            <ListItemText primary={t('NavBarVertical.Labels.Vessels')} />
          </NavLink>
        </MenuItem>
        {isVoyageAdmin && <MenuItem key={'NavVoyageList-key'}>
          <NavLink
              className={classes.navLinkMenuItem}
              to={RouteConstants.VOYAGE_LIST}
              onClick={handleDataMenuItemClose}
              id="NavVoyageList"
            >
            <ListItemText primary={t('NavBarVertical.Labels.Voyages')} />
          </NavLink>
        </MenuItem>}
      </Menu>
    </>


  const dashboardMenuItem = () => dashboardConfig && dashboardConfig.enabled && !agentPortal &&  
    <Tooltip title={t('NavBarVertical.Labels.Dashboard')}>
      <NavLink
        activeClassName={classes.activeLink}
        className={classes.navLink}
        to="/dashboard"
        id="NavDashboard"
      >
        <Icon fontSize="large">
          <ChartMultiline fontSize="large" />
        </Icon>
      </NavLink>
    </Tooltip>

  const documentationMenuItem = () => !agentPortal &&  
    <Tooltip title={t('NavBarVertical.Labels.Documentation')}>
      <NavLink
        activeClassName={classes.activeLink}
        className={classes.navLink}
        to="/documentation"
        id="NavDocumentation"
      >
        <Icon fontSize="large">
          <HelpCircleOutline fontSize="inherit" />
        </Icon>
      </NavLink>
    </Tooltip>

  const adminMenuItem = () => isAdmin && 
    <Tooltip title={t('NavBarVertical.Labels.Administration')}>
      <NavLink
        activeClassName={classes.activeLink}
        className={classes.navLink}
        to={location.pathname.startsWith("/admin") ? location.pathname : "/admin/general-settings"}
        id="NavAdmin"
      >
        <Icon fontSize="large">
          <Cog fontSize="inherit" />
        </Icon>
      </NavLink>
    </Tooltip>
  
  const menuItems = () => !agentPortal && 
    <>
      {requestsMenuItem()}
      {addPortCallMenuItem()}
      {boardMenuItem()} 
      {isVoyageAdmin && voyageBoardMenuItem()}
      {mapMenuItem()}
      {DataMenuItem()}
      {dashboardMenuItem()}
      {documentationMenuItem()}
    </>

  const agentPortalMenuItems = () => agentPortal &&
    <>
      {requestPortCallMenuItem()}
      {DataMenuItem()}
      {boardMenuItem()}
      {mapMenuItem()}
    </>

  return (
    <>
      <Box
        className={`${classes.navBarContainer} ${above ? classes.aboveAll : ''}`}
        display="flex"
        flexDirection="column"
      >
        {/* Menu Items dependent on whether agentPortal */}
        {agentPortal ? agentPortalMenuItems() : menuItems()}
        <Box height="4rem" />
        {/* User and Administration Options */}
        {adminMenuItem()}
      </Box>
      <Drawer 
        transitionDuration={{enter:transitionTime, exit: transitionTime}} 
        open={openDrawer} 
        classes={{paper: classes.paper}} 
        className={classes.drawer} 
        anchor={"left"} 
        onClose={() => setOpenDrawer(false)}
      >
        <div 
          className={classes.drawerContainer}
          id="NavBarVertical"
        >
          <div className={classes.noScroll}>
          <Typography variant="h5" className={classes.title}>
            {t('NavBarVertical.Labels.RequestInbox')}
          </Typography>
          </div>
          <div className={classes.requests}>
            <RequestInbox requests={requests} setOpenDrawer={setOpenDrawer} />
          </div>
        </div>
      </Drawer>
    </>
  )
}

//Wrapping the Component with the query
const NavBarVertical = () => {
  const requests = useContext(DataStoreContext).requests.filter(r => r.state === RequestState.REQUEST_STATE_PENDING || r.state === RequestState.REQUEST_STATE_NOTIFICATION);  
  const portCalls = useQuery(PortCall, {
    condition: c => c.status("ne", PortCallStatus.DELETED).status("ne", PortCallStatus.CANCELLED).status("ne", PortCallStatus.CLOSED),
    filter: p => p.status !== PortCallStatus.DELETED && p.status !== PortCallStatus.CANCELLED && p.status !== PortCallStatus.CLOSED
  });
  const requestsFiltered = useMemo(() => requests.filter(r => portCalls.some(p => p.id === r.requestPortCallId_)), [requests, portCalls]);
  return <NavBarVerticalInternal requests={requestsFiltered} />;
};


export default NavBarVertical;
