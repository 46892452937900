import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Box } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import AppHeader from '../containers/AppHeader';
import NavBarVertical from '../components/NavBarVertical';
import BerthStatusList from '../containers/BerthStatusList';
import MovementActionList from '../containers/MovementActionList';
import PortCallDetails from '../components/PortCall/PortCallDetails';
import ActionDetails from '../components/Action/ActionDetails';
import VesselEditPage from '../components/Vessel/VesselEditPage';
import PortMap from '../components/Map/PortMap';
import Dashboard from '../components/Dashboard/Dashboard';
import { DialogContextProvider } from '../contexts/dialog';
import { NavigationContextProvider } from '../contexts/navigation';
import { ImageFileContextProvider } from '../contexts/image';
import QuickPortCall from '../components/QuickPortCall/QuickPortCall';
import Documentation from '../components/Documentation';
import VesselCreatePage from '../components/Vessel/VesselCreatePage';
import PortCallBrowser from '../components/PortCall/PortCallBrowser';
import VesselList from '../components/Vessel/VesselList';
import Admin from '../components/Admin/Admin';
import useActiveActions from '../hooks/useActiveActions';
import usePortCallDetails from '../hooks/usePortCallDetails';
import ConfirmDialog from '../components/Dialog/ConfirmDialog';
import SyncingSnackbar from '../components/Snackbar/SyncingSnackbar';
import AuditLogSnackbar from '../components/Snackbar/AuditLogSnackbar';
import { MarlinAuthProvider } from '../contexts/marlinAuthContext';
import { mapConfig } from '../environment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import RouteConstants from '../constants/RouteConstants'
import useFeatureSetting from '../hooks/useFeatureSetting';
import PortCallList from '../components/PortCall/PortCallList';
import VoyageBrowser from '../components/Voyage/VoyageBrowser/VoyageBrowser';
import VoyageDetails from '../components/Voyage/VoyageTimeline/VoyageDetails';
import VoyageSchedule from '../components/Voyage/VoyageSchedule/VoyageSchedule';

const AppRoutes = ({ confirmation, resetConfirmation }) => {
  // 
  const { message, callback} = confirmation;
  const activeActions = useActiveActions();
  const portCallDetails = usePortCallDetails();
  const { t } = useTranslation();
  const history = useHistory();
  const isPortCallBrowserEnabled = useFeatureSetting('portcall-browser');
  // Expose react-router to the testing framework
  useEffect(() => {
    window.__E2E__.history = history;
  }, [history]);
  
  return (
    <Box
      display="flex"
      height="100%"
      flexDirection="column"
    >
      <SnackbarProvider 
        maxSnack={3} 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
        preventDuplicate={true}
        autoHideDuration={10000}
      >
        <DialogContextProvider>
          <Route path="/">
            <AppHeader />
          </Route>

          <Box
            display="flex"
            height="100%"
          >
            <NavigationContextProvider>
              <ImageFileContextProvider>
                <MarlinAuthProvider wmsList={mapConfig.wms}>
                <Route path="/">
                  <NavBarVertical />
                </Route>
                <Route exact path={['/', '/lists/berths']}>
                  <BerthStatusList activeActions={activeActions} />
                </Route>
                <Route exact path={['/', '/lists/movements']}>
                  <MovementActionList activeActions={activeActions} />
                </Route>
                <Route exact path={RouteConstants.VOYAGE_LIST}>
                  <VoyageBrowser />
                </Route>
                <Route
                  exact
                  path={`${RouteConstants.VOYAGE}/:voyageId`}
                  render={({ match: { params } }) => <VoyageDetails {...params} />}
                />
                <Route exact path={RouteConstants.PORTCALL_LIST}>
                  {/* PortCallBrowser show list of archived and unarchived portcalls, PortCallList only show list of unarchived portcalls*/}
                  {isPortCallBrowserEnabled ? <PortCallBrowser /> : <PortCallList/>}
                </Route>
                <Route exact path={RouteConstants.VESSEL_LIST}>
                  <VesselList />
                </Route>
                <Route
                  exact
                  path="/port-call/:id/(requests)?/:requestId?"
                  render={({ match: { params } }) => <PortCallDetails {...params} portCallDetails={portCallDetails} />}
                />
                <Route
                  exact
                  path="/port-call/:portCallId/action/:actionId/(requests)?/:requestId?"
                  render={({ match: { params } }) => <ActionDetails {...params} portCallDetails={portCallDetails} />}
                />

                <Route exact path="/quick">
                  <QuickPortCall />
                </Route>

                <Route exact path="/vessel-edit/:portCallId/">
                  <VesselEditPage />
                </Route>

                <Route exact path="/vessels/:vesselId/">
                  <VesselEditPage />
                </Route>

                <Route exact path="/vessel-create/:disablePortcallUpdate?">
                  <VesselCreatePage />
                </Route>

                <Route exact path="/voyage-schedule">
                  <VoyageSchedule />
                </Route>

                <Route exact path="/map">
                  <PortMap />  
                </Route>

                <Route exact path="/dashboard">
                  <Dashboard />
                </Route>

                <Route exact path="/documentation">
                  <Documentation />
                </Route>
                
                <Route path="/admin">
                  <Admin />
                </Route>
                </MarlinAuthProvider>
              </ImageFileContextProvider>
            </NavigationContextProvider>

          </Box>
        </DialogContextProvider>
        {!!message && (
          <ConfirmDialog
            open={!!message}
            title={t("Common.Labels.UnsavedChanges")}
            confirmText={t("Common.Buttons.Leave")}
            message={message}
            onClose={() => { callback(false); resetConfirmation(); }}
            onConfirm={() => { callback(true); resetConfirmation(); }}
          />
        )}
        <AuditLogSnackbar/>
        <SyncingSnackbar />
      </SnackbarProvider>
    </Box>
  );
};

const Routes = props => {
  
  const initialConfirmation = { message: null, callback: null};
  const [ confirmation, setConfirmation ] = useState(initialConfirmation);

  const resetConfirmation = () => setConfirmation(initialConfirmation);

  return(
    <BrowserRouter
      getUserConfirmation={(message, callback) => {
        setConfirmation({message, callback})
      }}
    >
      <AppRoutes {...props} confirmation={confirmation} resetConfirmation={resetConfirmation} />
    </BrowserRouter>
  );
};

export default Routes;
