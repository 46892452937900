import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useEffect } from 'react';
import { useState } from 'react';
import { dashboardConfig } from '../../environment.js';

export default () => {
  const [ready, setReady] = useState(false); // incrementing will force reloads

  const setup = async () => {
    setReady(true);
  };

  // init
  useEffect(() => {
    setup();
  }, []);

  if(!dashboardConfig || !dashboardConfig.enabled || !dashboardConfig.tableauUrl) return null;

  return (
    <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
      {!ready && <CircularProgress />}
      {ready && <iframe
        src={dashboardConfig.tableauUrl}
        width="100%"
        height="100%"
        frameBorder={0}
      />}
    </Box>
  );
}